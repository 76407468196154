import React from "react";

export default function Home() {
  return (
    <div className="landing-page">
      <div className="hero">
        <h1 className="title">Welcome to weVibe</h1>
        <p className="subtitle">Connect Anonymously, Share Freely</p>
        <div className="cta">
          <button className="btn btn-primary">Get Started</button>
          <button className="btn btn-secondary">Learn More</button>
        </div>
      </div>
      <div className="features">
        <div className="feature">
          <i className="fas fa-user-secret fa-3x"></i>
          <h2>Complete Anonymity</h2>
          <p>Connect with others without revealing your identity.</p>
        </div>
        <div className="feature">
          <i className="fas fa-map-marker-alt fa-3x"></i>
          <h2>Hyperlocal Connections</h2>
          <p>Find people nearby and create meaningful connections.</p>
        </div>
        <div className="feature">
          <i className="fas fa-lock fa-3x"></i>
          <h2>Privacy First</h2>
          <p>
            Your privacy is our top priority. Feel secure in every interaction.
          </p>
        </div>
      </div>
      <footer className="footer">
        <p>&copy; 2024 WeVibe. All Rights Reserved.</p>
      </footer>
    </div>
  );
}
