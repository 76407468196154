import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { get } from "lodash-es";
const SharedPost = () => {
  const { postShareId } = useParams();
  // https://t2w7brwdse.execute-api.us-east-1.amazonaws.com/dev/posts/LxFdPHUTmq1g/share
  const [post, setPost] = useState<any>({});
  const [loading, setLoading] = useState(true);
  // Get the shared post from the API
  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_WEVIBE_API}${postShareId}/share`)
      .then((response) => response.json())
      .then((response) => {
        setPost(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [postShareId]);
  return (
    <div>
      {loading ? (
        <div style={styles.main}>
          {" "}
          <i className="fas fa-spinner fa-3x spinner"></i>Loading...
        </div>
      ) : (
        <div style={styles.main}>
          <h1>weVibe</h1>
          {/* <div style={styles.container}> */}
          <div style={styles.imageContainer} className="imageContainer">
            <div style={styles.title}>
              <i className="fa fa-compass" aria-hidden="true"></i>
              <span>{get(post, "location.toponymName", "")}</span>
            </div>
            {/* Replace 'your_image_url.jpg' with the actual URL of the shared image */}
            <img
              style={styles.sharedImage}
              src={`${process.env.REACT_APP_IMAGE_STORE}${post.shareImageUrl}`}
              alt="Shared Post"
            />
            <div style={styles.iconBar}>
              <div style={styles.iconTray}>
                <div style={styles.iconItem}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      width: 30,
                      height: 30,
                    }}
                    viewBox="0 0 30 30"
                  >
                    <path
                      d="M15.004 6.164c-2.758-2.144-6.754-1.953-9.285.582a7.028 7.028 0 000 9.942l8.617 8.62a.94.94 0 001.324 0l8.621-8.62a7.035 7.035 0 000-9.942c-2.523-2.523-6.52-2.7-9.277-.582zM15 8.348c.41-.008.625-.243.66-.27 2.207-1.922 5.29-2.012 7.293-.008a5.16 5.16 0 010 7.293L15 23.32l-7.957-7.957a5.16 5.16 0 010-7.293 5.157 5.157 0 017.293.004.937.937 0 00.664.274zm0 0"
                      fill={"##000"}
                      fillRule="evenodd"
                      fillOpacity={1}
                    />
                  </svg>
                  <span>{get(post, "stats.likes", 0)}</span>
                </div>
                <div style={styles.iconItem}>
                  <svg
                    viewBox="0 0 24 24"
                    width="28"
                    height="28"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z" />
                  </svg>
                  <span>{get(post, "stats.messageCount", 0)}</span>
                </div>
                <div style={styles.iconItem}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      fill={"##000"}
                      d="M17 8.67v-3.4c.62-.54 1-1.34 1-2.27 0-1.71-1.29-3-3-3H9C7.29 0 6 1.29 6 3c0 .93.38 1.73 1 2.27v3.4c-1.31.99-2 2.86-2 4.33 0 1.71 1.29 3 3 3h3v5l1 3 1-3v-5h3c1.71 0 3-1.29 3-3 0-1.47-.69-3.34-2-4.33zM16 14H8c-.6 0-1-.39-1-1 0-1.22.79-3 2-3V4c-.6 0-1-.39-1-1s.4-1 1-1h6c.6 0 1 .39 1 1s-.4 1-1 1v6c1.21 0 2 1.78 2 3 0 .61-.4 1-1 1z"
                    />
                  </svg>
                  <span>{get(post, "stats.pinnedCount", 0)}</span>
                </div>
              </div>
              <div style={styles.iconTray}>
                <div style={styles.iconItem}>
                  <svg
                    style={{
                      width: 26,
                      height: 26,
                    }}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={"##000"}
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                  </svg>
                  <span>{get(post, "stats.views", 0)}</span>
                </div>
                <div style={styles.iconItem}>
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z" />
                  </svg>
                  <span>{get(post, "stats.shareCount", 0)}</span>
                </div>
              </div>
            </div>
          </div>
          <a
            style={styles.goToApp}
            onClick={() => (window.location.href = `wevibe://post?${post._id}`)}
          >
            Read full post on app...
          </a>
          {/* </div> */}
        </div>
      )}
    </div>
  );
};

const styles: any = {
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100vh",
    //background image, no repeat and cover
    // backgroundImage: `url(/launch_screen.png)`,
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
  },

  imageContainer: {
    fontFamily: "Arial, sans-serif",
    textAlign: "center",
    // margin: "50px auto",

    borderRadius: "10px",
    overflow: "hidden",
    backgroundColor: "#fff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
  sharedImage: {
    width: "100%",
    height: "auto",
    display: "block",
  },
  goToApp: {
    display: "block",
    margin: "20px auto",
    padding: "15px 60px",
    backgroundColor: "#4CAF50",
    color: "#fff",
    textDecoration: "none",
    borderRadius: "5px",
  },
  iconTray: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "20px",
  },
  iconBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px",
  },
  //icon and count
  iconItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    padding: "10px 10px",
  },
};

export default SharedPost;
