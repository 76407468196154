import React from "react";

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p>
        Welcome to WeVibe, the hyperlocal social media app prioritizing
        anonymity. By using our app, you agree to the following Terms of
        Service:
      </p>

      <h2>1. User Responsibilities</h2>
      <p>
        <strong>1.1 Anonymity:</strong> WeVibe is designed to protect your
        anonymity. Users are prohibited from sharing any personally identifiable
        information within the app, including names, email addresses, phone
        numbers, or addresses.
      </p>
      <p>
        <strong>1.2 Respect:</strong> Users must treat others with respect and
        refrain from any behavior that is abusive, discriminatory, or offensive.
        Harassment of any kind will not be tolerated.
      </p>
      <p>
        <strong>1.3 Legal Compliance:</strong> Users are responsible for
        complying with all applicable laws while using WeVibe. This includes but
        is not limited to, laws regarding defamation, copyright, and privacy.
      </p>

      <h2>2. Prohibited Activities</h2>
      <p>
        <strong>2.1 Impersonation:</strong> Users are prohibited from
        impersonating any person or entity within the app.
      </p>
      <p>
        <strong>2.2 Spam:</strong> Any form of spamming, including excessive
        posting or sending unsolicited messages, is strictly prohibited.
      </p>
      <p>
        <strong>2.3 Illegal Content:</strong> Users may not share or promote any
        content that is illegal, including but not limited to, pornography, drug
        trafficking, or hate speech.
      </p>

      <h2>3. Account Termination</h2>
      <p>
        WeVibe reserves the right to terminate or suspend any user account that
        violates these Terms of Service. Users found to be in violation may be
        banned from using the app permanently.
      </p>

      <h2>4. Limitation of Liability</h2>
      <p>
        <strong>4.1 User Acknowledgment:</strong> By using WeVibe, users
        acknowledge that the app is provided "as is" and without warranties of
        any kind.
      </p>
      <p>
        <strong>4.2 Indemnification:</strong> Users agree to indemnify and hold
        harmless WeVibe and its affiliates from any claims, damages, or losses
        arising from their use of the app.
      </p>

      <h2>5. Changes to the Terms</h2>
      <p>
        WeVibe reserves the right to update these Terms of Service as needed.
        Any changes will be posted within the app, and your continued use of
        WeVibe constitutes acceptance of these changes.
      </p>
    </div>
  );
};

export default TermsOfService;
