import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import Share from "./pages/Share";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/about" Component={Home} />
          <Route path="/share/:postShareId" Component={Share} />
          <Route path="/privacy" Component={PrivacyPolicy} />
          <Route path="/terms" Component={TermsOfService} />
          <Route path="/users" Component={Home} />
          <Route path="/" Component={Home} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
