import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        Welcome to WeVibe, the hyperlocal social media app prioritizing
        anonymity. Your privacy and security are important to us. This Privacy
        Policy outlines how we collect, use, and protect your personal
        information while using our app.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        <strong>1.1 Personal Information:</strong> WeVibe does not require any
        personal information to create an account or use our services. We do not
        collect names, email addresses, phone numbers, or any other identifying
        information.
      </p>
      <p>
        <strong>1.2 Usage Information:</strong> We collect anonymous usage data
        to improve our services. This includes information such as device type,
        IP address, browser type, and pages visited within the app. This
        information is used for analytics purposes only.
      </p>
      <p>
        <strong>1.3 Location Data:</strong> To provide hyperlocal services,
        WeVibe may collect and store your approximate location. This data is
        anonymized and used solely to connect you with other users nearby.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>
        <strong>2.1 Anonymity:</strong> WeVibe is designed to prioritize your
        anonymity. Your activity within the app is not linked to any personally
        identifiable information.
      </p>
      <p>
        <strong>2.2 App Improvements:</strong> We use collected data to analyze
        trends and improve the app's functionality. This helps us create a
        better experience for all users.
      </p>

      <h2>3. Data Security</h2>
      <p>
        <strong>3.1 Encryption:</strong> WeVibe employs industry-standard
        encryption protocols to protect your data during transmission and
        storage.
      </p>
      <p>
        <strong>3.2 Third-Party Access:</strong> We do not sell, rent, or share
        your personal information with third parties for marketing purposes.
      </p>
      <p>
        <strong>3.3 User Responsibility:</strong> While we take all reasonable
        measures to secure your data, users are responsible for their own
        actions within the app. Please use caution when sharing any information,
        as WeVibe cannot control the actions of other users.
      </p>

      <h2>4. Children's Privacy</h2>
      <p>
        WeVibe is intended for users aged 18 and above. We do not knowingly
        collect any personal information from children under the age of 13. If
        we become aware of such information being collected, we will take
        immediate steps to delete it.
      </p>

      <h2>5. Changes to this Policy</h2>
      <p>
        WeVibe reserves the right to update this Privacy Policy as needed. Any
        changes will be posted within the app, and your continued use of WeVibe
        constitutes acceptance of these changes.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
